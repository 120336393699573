<app-navbar-one></app-navbar-one>

<div class="page-title-area service-bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Services</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Services</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="services-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Awesome</span> Services</h2>
            <p>We offer tailor-made software development services for startups, medium-sized companies, and large enterprises, from dedicated teams to custom software development</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-cellphone"></i>
                    </div>
                    <h3>Mobile App Development</h3>
                    <p>We build mobile solutions to improve your business effectiveness and efficiency. We have a strong knowledge base to guide you along the process of mobile application development.</p>
                    <!-- <a routerLink="/services-details" class="read-btn">Read More</a> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-deb0fe">
                        <i class="flaticon-setting"></i>
                    </div>
                    <h3>Web Development</h3>
                    <p>As a reliable custom web application development company, We has earned good and reliable reputation, so you can trust us with your largest and most ambitious projects</p>
                    <!-- <a routerLink="/services-details" class="read-btn">Read More</a> -->
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-fcc774">
                        <i class="flaticon-cellphone"></i>
                    </div>
                    <h3>Artificial Intelligence</h3>
                    <p>Artificial Intelligence (AI) is probably one of the most mentioned words in the Technology world right now due to its effect, usage, and prevalence in everyday activities and applications.</p>
                    <!-- <a routerLink="/services-details" class="read-btn">Read More</a> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-79e8e2">
                        <i class="flaticon-promotion"></i>
                    </div>
                    <h3>Search Engine Optimization</h3>
                    <p>We at Abitz, a professional SEO company, by working with us, you have a complete team of digital marketing specialists working toward your online business success. So, whatever your objective is, like steering online traffic, bringing in website inquiries, boosting profitability, growing brand awareness, we have got you completely covered. You could say our SEO strategies and tactics are ready for your digital success.</p>
                    <!-- <a routerLink="/services-details" class="read-btn">Read More</a> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-fe929f">
                        <i class="flaticon-optimize"></i>
                    </div>
                    <h3>Specialized Testing</h3>
                    <p>We provides you with a multi-skilled QA & testing team. Experts are chosen based on their skills and qualifications to suit your project’s demands.</p>
                    <!-- <a routerLink="/services-details" class="read-btn">Read More</a> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-84b7fd">
                        <i class="flaticon-shopping-cart"></i>
                    </div>
                    <h3>Application Support and Maintenance</h3>
                    <p>An application is not completed once the development phase is over. Understanding this concern, Whether you have developed a project with us in the past or just want to keep your project fresh and current, our team of IT experts can handle all your requests and expectations.</p>
                    <!-- <a routerLink="/services-details" class="read-btn">Read More</a> -->
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>