<app-navbar-one></app-navbar-one>

<div class="page-title-area item-bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Projects</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Projects</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="protfolio-section ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/projects-details"><img src="assets/img/protfolio/img1.png" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>Creative Web Develop</h3>
                        <span>Web Design</span>
                        <a routerLink="/projects-details" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/projects-details"><img src="assets/img/protfolio/img2.png" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>Digital Services</h3>
                        <span>App Development</span>
                        <a routerLink="/projects-details" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/projects-details"><img src="assets/img/protfolio/img3.png" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>Complex Design</h3>
                        <span>Software Development</span>
                        <a routerLink="/projects-details" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/projects-details"><img src="assets/img/protfolio/img4.png" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>Creative Web Develop</h3>
                        <span>React Development</span>
                        <a routerLink="/projects-details" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/projects-details"><img src="assets/img/protfolio/img5.png" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>Digital Services</h3>
                        <span>E-commerce Development</span>
                        <a routerLink="/projects-details" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-protfolio-box">
                    <div class="image">
                        <a routerLink="/projects-details"><img src="assets/img/protfolio/img6.png" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3>Complex Design</h3>
                        <span>Software Engineering</span>
                        <a routerLink="/projects-details" class="link-btn"></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a routerLink="/projects-two" class="prev page-numbers"><i class="flaticon-left"></i></a>
                    <a routerLink="/projects-two" class="page-numbers">1</a>
                    <span class="page-numbers current" aria-current="page">2</span>
                    <a routerLink="/projects-two" class="page-numbers">3</a>
                    <a routerLink="/projects-two" class="page-numbers">4</a>
                    <a routerLink="/projects-two" class="next page-numbers"><i class="flaticon-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>