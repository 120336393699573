<app-navbar-three></app-navbar-three>

<div class="main-banner-area-five">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="main-banner-content">
                            <!-- <span>Welcome To App Showcase</span> -->
                            <h1>Awesome Apps For Your Modern Lifestyle</h1>
                            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing incididunt ut laboredolore magna aliqua elsed  tempomet, consectetur adipiscing.</p>
                            <div class="banner-btn">
                                <a routerLink="/" class="default-btn">Get Started</a>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="banner-image">
                            <img src="assets/img/home-five/1.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>

    <div class="banner-shape">
        <img src="assets/img/home-five/shape.png" alt="image">
    </div>
</div>

<section class="learn-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="learn-content">
                    <h2>WHO WE ARE</h2>
                    <div class="bar"></div>
                    <p>Abitz Technologies private limited founded and incorporated in India in 2021, with the vision of building an organisation that included a talent base of various advanced technologies. It provides software as a service in deign(UI and functional design), development(Coding), testing and software architecture for Mobile application development(Android & iOS), Web and Desktops, and also ABITZ develops own in-house product for B2B and B2C products with modern and scalable architecture and languages(software languages).</p>
                </div>
            </div>
            <!-- <div class="col-lg-6 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="learn-inner-content mb-30">
                            <div class="icon">
                                <i class="flaticon-blog"></i>
                            </div>
                            <h3>Easy To edit</h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit do incididunt aliqua.</p>
                            <a routerLink="/" class="read-btn">Read More</a>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="learn-inner-content mb-30">
                            <div class="icon bg-ba60fc">
                                <i class="flaticon-blueprint"></i>
                            </div>
                            <h3>Full Protection</h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit do incididunt aliqua.</p>
                            <a routerLink="/" class="read-btn">Read More</a>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="learn-inner-content">
                            <div class="icon bg-04cfc4">
                                <i class="flaticon-clock"></i>
                            </div>
                            <h3>High Speed</h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit do incididunt aliqua.</p>
                            <a routerLink="/" class="read-btn">Read More</a>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="learn-inner-content">
                            <div class="icon bg-f9b854">
                                <i class="flaticon-software"></i>
                            </div>
                            <h3>Design Branding</h3>
                            <p>Lorem ipsum dolor sit amet consectetur adipiscing elit do incididunt aliqua.</p>
                            <a routerLink="/" class="read-btn">Read More</a>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section>

<!-- <section class="app-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="app-image">
                    <img src="assets/img/app.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="app-content">
                    <h3>How Does The App Work?</h3>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
                </div>
                <div class="app-inner-text">
                    <div class="icon">
                        <i class="flaticon-laptop"></i>
                    </div>
                    <h3>Make your Profile</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                </div>
                <div class="app-inner-text">
                    <div class="icon">
                        <i class="flaticon-cloud-computing"></i>
                    </div>
                    <h3>Download It For Free</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                </div>
                <div class="app-inner-text">
                    <div class="icon">
                        <i class="flaticon-cellphone"></i>
                    </div>
                    <h3>Enjoy This App</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="data-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="data-content">
                    <h3>Easy to Manage Your All Data by This App</h3>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="data-inner-content">
                                <i class="flaticon-software"></i>
                                <h3>Modern Design</h3>
                                <p>Lorem ipsum dolor</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="data-inner-content bg-facf34">
                                <i class="flaticon-setting"></i>
                                <h3>Creative Idea</h3>
                                <p>Lorem ipsum dolor</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="data-image">
                    <img src="assets/img/data.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section> -->

<section class="services-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Awesome</span> Services</h2>
            <p>We offer tailor-made software development services for startups, medium-sized companies, and large enterprises, from dedicated teams to custom software development</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-cellphone"></i>
                    </div>
                    <h3>Mobile App Development</h3>
                    <p>We build mobile solutions to improve your business effectiveness and efficiency. We have a strong knowledge base to guide you along the process of mobile application development.</p>
                    <!-- <a routerLink="/services-details" class="read-btn">Read More</a> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-deb0fe">
                        <i class="flaticon-setting"></i>
                    </div>
                    <h3>Web Development</h3>
                    <p>As a reliable custom web application development company, We has earned good and reliable reputation, so you can trust us with your largest and most ambitious projects</p>
                    <!-- <a routerLink="/services-details" class="read-btn">Read More</a> -->
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-fcc774">
                        <i class="flaticon-cellphone"></i>
                    </div>
                    <h3>Artificial Intelligence</h3>
                    <p>Artificial Intelligence (AI) is probably one of the most mentioned words in the Technology world right now due to its effect, usage, and prevalence in everyday activities and applications.</p>
                    <!-- <a routerLink="/services-details" class="read-btn">Read More</a> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-79e8e2">
                        <i class="flaticon-promotion"></i>
                    </div>
                    <h3>Search Engine Optimization</h3>
                    <p>We at Abitz, a professional SEO company, by working with us, you have a complete team of digital marketing specialists working toward your online business success. So, whatever your objective is, like steering online traffic, bringing in website inquiries, boosting profitability, growing brand awareness, we have got you completely covered. You could say our SEO strategies and tactics are ready for your digital success.</p>
                    <!-- <a routerLink="/services-details" class="read-btn">Read More</a> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-fe929f">
                        <i class="flaticon-optimize"></i>
                    </div>
                    <h3>Specialized Testing</h3>
                    <p>We provides you with a multi-skilled QA & testing team. Experts are chosen based on their skills and qualifications to suit your project’s demands.</p>
                    <!-- <a routerLink="/services-details" class="read-btn">Read More</a> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon bg-84b7fd">
                        <i class="flaticon-shopping-cart"></i>
                    </div>
                    <h3>Application Support and Maintenance</h3>
                    <p>An application is not completed once the development phase is over. Understanding this concern, Whether you have developed a project with us in the past or just want to keep your project fresh and current, our team of IT experts can handle all your requests and expectations.</p>
                    <!-- <a routerLink="/services-details" class="read-btn">Read More</a> -->
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section>

<!-- <section class="customer-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="customer-content">
                    <h3>20k Customer Use App now</h3>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley</p>
                    <div class="customer-btn">
                        <a routerLink="/" class="default-btn">App Store</a>
                        <a routerLink="/" class="optional-btn">Play Store</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="customer-image">
                    <img src="assets/img/customer.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="review-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <div class="icon">
                                <i class="flaticon-check"></i>
                            </div>
                            <h3><span class="odometer" data-count="950">00</span></h3>
                            <p>Completed Project</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <div class="icon">
                                <i class="flaticon-happy"></i>
                            </div>
                            <h3><span class="odometer" data-count="150">00</span></h3>
                            <p>Happy Clients</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <div class="icon">
                                <i class="flaticon-technical-support"></i>
                            </div>
                            <h3><span class="odometer" data-count="550">00</span></h3>
                            <p>Multi Service</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-counter">
                            <div class="icon">
                                <i class="flaticon-trophy"></i>
                            </div>
                            <h3><span class="odometer" data-count="750">00</span></h3>
                            <p>Winning Awards</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="review-image">
                    <img src="assets/img/review.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="team-section pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Expert Team</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/team/img1.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Alex Maxwel</h3>
                        <span>Founder</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/team/img2.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Williams Halimton</h3>
                        <span>Manager</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/team/img3.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Juhon Dew</h3>
                        <span>CEO</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/4.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/5.svg" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/6.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/7.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/8.png" alt="image"></div>
    </div>
</section> -->

<!-- <section class="clients-section bg-background ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>What Our <span>Clients</span> Says</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="clients-slider owl-carousel owl-theme">
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, printing printing sed do eiusmod tempor incididunt ut labore et printing dolore magna aliqua. Quis ipsum printing suspendisse ultricesss gravida. Risus commodo viverra printing maecenas accumsan lacus vel facilisis printing. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                <div class="clients-content">
                    <h3>Moris Jacker</h3>
                    <span>Web Developer</span>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, printing printing sed do eiusmod tempor incididunt ut labore et printing dolore magna aliqua. Quis ipsum printing suspendisse ultricesss gravida. Risus commodo viverra printing maecenas accumsan lacus vel facilisis printing. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                <div class="clients-content">
                    <h3>Alex Maxwel</h3>
                    <span>Agent Management</span>
                </div>
            </div>
            <div class="clients-item">
                <div class="icon">
                    <i class="flaticon-left-quotes-sign"></i>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, printing printing sed do eiusmod tempor incididunt ut labore et printing dolore magna aliqua. Quis ipsum printing suspendisse ultricesss gravida. Risus commodo viverra printing maecenas accumsan lacus vel facilisis printing. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                <div class="clients-content">
                    <h3>Edmond Halley</h3>
                    <span>Web Designer</span>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="blog-section pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Latest</span> News</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua.</p>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-item">
                    <div class="image">
                        <a routerLink="/blog-details"><img src="assets/img/blog/img1.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <span>20 March 2020</span>
                        <h3><a routerLink="/blog-details">Great Tips To Earn More Money From Digital Industry</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor</p>
                        <a routerLink="/blog-details" class="read-more">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-item">
                    <div class="image">
                        <a routerLink="/blog-details"><img src="assets/img/blog/img2.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <span>25 April 2020</span>
                        <h3><a routerLink="/blog-details">The Billionaire Guide On Design That will Get You Rich</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor</p>
                        <a routerLink="/blog-details" class="read-more">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-blog-item">
                    <div class="image">
                        <a routerLink="/blog-details"><img src="assets/img/blog/img3.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <span>30 July 2020</span>
                        <h3><a routerLink="/blog-details">Making Peace With The Feast Or Famine Of Freelancing</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor</p>
                        <a routerLink="/blog-details" class="read-more">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->